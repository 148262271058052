






























import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingStatus, OpenHomes } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

import ListingCard from '../components/ListingCard.vue';
import Loader from '../components/UI/Loader.vue';

@Component({
  components: {
    ListingCard,
    Loader,
  },
})
export default class InspectionTimes extends Mixins(View) {
  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    HaveInspections: true,
  });

  private openHomes?: OpenHomes;

  private loading = true;

  mounted() {
    API.Listings.GetOpenHomes(this.filter).then((data) => {
      this.loading = false;
      this.openHomes = data;
    });
  }
}
